<template>
  <b-container class="container-content">
    <b-row class="justify-content-center">
      <b-col :xl="isUpdateBooking? 5 : 8" class="px-4 card-container shadow-lg text-center">
        <i class="fas mark-icon" :class="iconStyle"></i>
        <p v-if="!isUpdateBooking" v-html="bookingLabel">
        </p>
        <p v-if="isPaymentNotComplete">{{ $t('alias.ref_no') }}: {{ paymentBody.refno }}</p>
        <p>{{ message }}</p>
        <b-button
          variant="primary"
          size="md"
          class="mt-4 p-2"
          block
          v-if="isSuccess"
          @click="goToPaymentHistory"
          >{{ buttonText }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { Component } from 'vue-property-decorator'
const BookingStore = createNamespacedHelpers('booking')
import { isProduction } from '../../utils'
@Component({
  props: ['errorMessage', 'status'],
  computed: {
    ...BookingStore.mapState(['paymentResult', 'paymentBody']),
  },
  created() {
    this.bodyPaySolution = JSON.parse(localStorage.bodyPaySolution)
    this.paymentResult = JSON.parse(localStorage.paymentResult)
    if (!this.isUpdateBooking && isProduction() && this.paymentResult.status === 200) {
      this.$gtag.event('conversion', {
        send_to: 'AW-685115829/6OegCM_e6eIBELWT2MYC',
        transaction_id: this.bodyPaySolution.refno || '',
      })
      fbq('init', '202147018091606')
      fbq('track', 'PageView')
    }
  },
})
export default class BookingConfirm extends Vue {
  bodyPaySolution = undefined
  paymentResult = undefined

  get buttonText() {
    if (this.isUpdateBooking) {
      console.log('localStorage.paymentResult', localStorage.paymentResult)
      return JSON.parse(localStorage.paymentResult).type === 'one-time'
        ? this.$t('button.return_to_my_booking')
        : this.$t('button.return_to_my_multi_package')
    } else {
      return this.$t('button.return_to_my_booking')
    }
  }

  get callbackPath() {
    if (this.isUpdateBooking) {
      return this.paymentResult.type === 'one-time' ? `MyBooking` : `MyMultiPackage`
    }
    return ``
  }

  get isPaymentNotComplete() {
    return this.paymentResult.errorMessage === 'payment_not_completed'
  }

  get message() {
    console.log('this.isUpdateBooking', this.paymentResult.type)
    if (this.isPaymentNotComplete) return this.$t('error.payment_waiting_to_confirm')
    if (this.paymentResult.errorMessage)
      return `${this.$t('alias.error')}: ${this.$t(`error.${this.paymentResult.errorMessage}`)}`
    if (this.isUpdateBooking) {
      if (this.paymentResult.status.toString().startsWith('4')) return this.$t('error.can_not_update_booking')
      console.log('this.isUpdateBooking', this.paymentResult.type)
      return this.paymentResult.type === 'one-time'
        ? this.$t('booking_confirm.you_have_updated_your_one_time_booking')
        : this.$t('booking_confirm.you_have_updated_your_multi_package_booking')
    }
    return this.$t('booking_confirm.you_will_now_recieve_an_email')
  }

  get bookingLabel() {
    if (this.isPaymentNotComplete) return ``
    const total = parseFloat(this.bodyPaySolution.total).toLocaleString("en-US", { minimumFractionDigits: 2 })
    if(this.isSuccess) {
      dataLayer.push({'event':'order_confirm','order_value':total});
    }
    return this.isSuccess
      ? this.$t('booking_confirm.payment_confirmed', { total: total })
      : this.$t('booking_confirm.booking_failed')
  }

  get isUpdateBooking() {
    return this.paymentResult.isUpdateBooking
  }

  get isBookingOneTime() {
    return this.paymentResult
  }

  get isSuccess() {
    return localStorage.isPaid
  }

  get iconStyle() {
    if (this.isPaymentNotComplete) return `fa-check-circle text-primary`
    if (!this.isSuccess) return `fa-times-circle text-danger`
    console.log(JSON.parse(localStorage.paymentResult).status)
    if (this.isUpdateBooking && this.paymentResult.status.toString().startsWith('4')) return `fa-times-circle text-danger`
    return JSON.parse(localStorage.paymentResult).status === 200 ||
      this.paymentResult.type === 'one-time'
      ? `fa-check-circle text-success`
      : `fa-check-circle text-warning`
    // return `fa-check-circle text-success`;
  }

  goToPaymentHistory() {
    localStorage.isPaid = null
    localStorage.paymentResult = null
    localStorage.payment = null
    if (this.callbackPath) {
      this.$router.push({ name: this.callbackPath })
    } else {
      this.$router.push({ name: 'MyBooking' })
    }
  }
}
</script>

<style scoped>
.mark-icon {
  font-size: 4em;
  margin-bottom: 16px;
}
.card-container {
  min-height: auto;
}
</style>
